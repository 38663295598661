<template>
    <div class="dropdown lg:w-full h-full" v-if="post && post.status">
        <button
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="flex items-center justify-content-between px-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full h-full"
        >
            <i :class="post.status.icon" class="pr-2 py-2 color-white"></i>
            {{ post.status.name }}
            <i
                v-if="isLoadingStatus === true"
                class="fas fa-circle-notch fa-spin ml-2 text-white"
            ></i>
            <svg
                class="ml-2 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                ></path>
            </svg>
        </button>

        <div
            v-if="formTypeStatuses"
            class="dropdown-menu"
            :class="{ loading: isLoadingStatus }"
            aria-labelledby="dropdownMenuButton"
        >
            <a
                v-for="(status, statusIndex) in formTypeStatuses[
                    post.form.form_type_id
                ]"
                :key="status.id"
                class="dropdown-item d-flex w-100 justify-center pr-4"
                @click.prevent="postStatus(statusIndex)"
            >
                <span class="mr-auto">{{ status.name }}</span>
                <span
                    v-if="status.id === post.status.id"
                    class="ml-auto status status-success mt-1.5"
                ></span>
            </a>
        </div>
    </div>
</template>
<style scoped>
.loading {
    background-color: grey;
}
</style>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: 'ButtonEditStatus',
    components: {},
    props: ['post'],
    data: function () {
        return {
            isLoadingStatus: false
        }
    },
    computed: {
        ...mapState('status', ['formTypeStatuses'])
    },
    created() {},
    methods: {
        ...mapActions('post', ['updateStatus', 'getPostHistories']),

        postStatus(statusIndex) {
            let status =
                this.formTypeStatuses[this.post.form.form_type_id][statusIndex]

            if (
                this.isLoadingStatus === true ||
                this.post.status_id === status.id
            ) {
                return
            }
            var updatedStatus = {
                id: this.post.id,
                status_id: status.id
            }

            this.isLoadingStatus = true

            this.updateStatus(updatedStatus)
                .then(() => {
                    this.getPostHistories(this.post.id)
                    this.post.status_id = updatedStatus.status_id
                    this.post.status = status
                })
                .catch((e) => {
                    this.$store.dispatch('dispatchError', e)
                })
                .finally(() => {
                    this.isLoadingStatus = false
                })
        }
    }
}
</script>
