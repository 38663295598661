<template>
    <button
        type="button"
        :class="{
            'cursor-pointer inline-flex items-center justify-content-between  px-3 py-2 mx-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500': true,
            'w-60 h-9 ml-5': variant != 'minimal',
            'w-11 h-9': variant == 'minimal',
            'mb-2': variant == 'minimal'
        }"
        @click.prevent="sendRemindToOperator"
        :disabled="loading"
        v-if="hasOperator"
    >
        <IconNotification class="h-8 w-8" v-show="!loading" />
        <i
            v-show="loading"
            class="fas fa-circle-notch fa-spin mr-2 h-4 w-4 text-white"
        ></i>
        <span v-if="variant != 'minimal'">
            {{ $t('post.page.button.restart') }}
        </span>
    </button>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    computed: {
        hasOperator: {
            get() {
                return this.post.racis.filter(
                    (raci) => raci.is_responsible == 1
                )
            }
        }
    },
    props: ['post', 'variant'],
    data() {
        return {
            loading: false
        }
    },
    methods: {
        ...mapActions('post', ['remindOperator']),
        sendRemindToOperator() {
            this.loading = true
            this.remindOperator(this.post.id)
                .then(() => {
                    this.$store.dispatch('toast', {
                        type: 'success',
                        title: this.$t('post.operator.reminded')
                    })
                })
                .catch((e) => {
                    this.$store.dispatch('dispatchError', e)
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                })
        }
    }
}
</script>
