<template>
    <div class="dropdown lg:w-full md:w-full h-full">
        <button
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :class="{
                'bg-indigo-500 hover:bg-indigo-700 focus:ring-indigo-500':
                    post.priority === null || post.priority === 0,
                'bg-green-600 hover:bg-green-700 focus:ring-green-500':
                    post.priority === 1,
                'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500':
                    post.priority === 2,
                'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500':
                    post.priority === 3,
                'bg-red-600 hover:bg-red-700 focus:ring-red-500':
                    post.priority === 4
            }"
            class="w-full inline-flex items-center px-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 justify-content-between"
        >
            <div class="inline-flex items-center py-2">
                <div v-if="post.priority === null || post.priority === 0">
                    <IconBolt class="-ml-0.5 mr-2 h-4 w-6" />
                </div>
                <div v-if="post.priority === 1">
                    <IconSearch class="-ml-0.5 mr-2 h-4 w-4" />
                </div>
                <div v-if="post.priority === 2">
                    <IconCheck class="-ml-0.5 mr-2 h-4 w-4" />
                </div>
                <div v-if="post.priority === 3">
                    <IconProgress class="-ml-0.5 mr-2 h-4 w-4" />
                </div>
                <div v-if="post.priority === 4">
                    <IconFire class="-ml-0.5 mr-2 h-4 w-4" />
                </div>
                {{ $t(`post.page.priority.${post.priority}`) }}
                <i
                    v-if="isLoadingPriority === true"
                    class="fas fa-circle-notch fa-spin ml-2 text-white"
                ></i>
            </div>
            <svg
                class="ml-2 w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                ></path>
            </svg>
        </button>
        <div
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            :class="{ loading: isLoadingPriority }"
        >
            <a
                v-for="(priority, index) in [0, 1, 2, 3, 4]"
                :key="index"
                class="dropdown-item d-flex w-100 justify-center pr-4"
                @click.prevent="postPriority(post, priority)"
            >
                <span class="mr-auto">{{
                    $t(`post.page.priority.${priority}`)
                }}</span>

                <span
                    v-if="priority === post.priority"
                    class="ml-auto status status-success mt-1.5"
                ></span>
            </a>
        </div>
    </div>
</template>
<style scoped>
.loading {
    background-color: grey;
}
</style>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'ButtonEditPriority',
    props: ['post'],
    data: function () {
        return {
            isLoadingPriority: false
        }
    },
    methods: {
        ...mapActions('post', ['updatePriority', 'getPostHistories']),
        postPriority(post, priority) {
            if (
                this.isLoadingPriority === true ||
                this.post.priority === priority
            ) {
                return
            }
            var updatedPriority = {
                id: post.id,
                priority: priority
            }
            this.isLoadingPriority = true

            this.updatePriority(updatedPriority)
                .then(() => {
                    console.log('test')
                    this.getPostHistories(post.id)
                    post.priority = updatedPriority.priority
                })
                .finally(() => {
                    this.isLoadingPriority = false
                })
        }
    }
}
</script>
