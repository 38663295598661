<template>
    <div>
        <b>{{ value.label }}</b
        ><br />
        <div v-if="typeof value.value === 'object'">
            <span v-for="item in value.value" :key="item.value">
                {{ item }}
            </span>
        </div>
        <div v-else>
            <span>{{ value.value }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CheckboxAttribute',
    props: ['value']
}
</script>

<style scoped></style>
